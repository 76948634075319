<template>
  <div class="privacy-policy">

    <h1>Cash Box Credit Union Privacy Notice</h1>

    <p>We are committed to protecting our members’ privacy. The credit union requires any information marked as
      mandatory for membership to either meet legal obligations or to enable us to perform our contract with
      you. Where you are not able to provide us with this information, we may not be able to open an account
      for you. Where we request further information about you not required for these reasons, we will ask you for your consent.</p>

    <h3>How we use your personal information</h3>
    <p>Cash Box Credit Union may process, transfer and/or share personal information in the following ways;</p>
    <p>For legal reasons</p>
    <ul>
      <li>Confirm your identity</li>
      <li>Perform activity for the prevention of financial crime</li>
      <li>Carry out internal and external auditing</li>
      <li>Record basic information about you on a register of members</li>
    </ul>

    <h3>For performance of our contract with you</h3>
    <ul>
      <li>Deal with your account(s) or run any other services we provide to you;</li>
      <li>Consider any applications made by you;</li>
      <li>Carry out credit checks and to obtain and provide credit references</li>
      <li>Undertake statistical analysis, to help evaluate the future needs of our members and to help manage our business</li>
      <li>To send you statements, new terms & conditions (including changes to this privacy statement), information
        about changes to the way your account(s) operate and notification of our annual general meeting.</li>
    </ul>

    <h3>For our legitimate interests</h3>
    <ul>
      <li>Recover any debts owed to us</li>
    </ul>

    <h3>With your consent</h3>
    <ul>
      <li>Maintain our relationship with you including marketing</li>
    </ul>

    <h3>Sharing your personal information</h3>
    <p>We will disclose information outside the Credit Union only to;</p>
    <ul>
      <li>Third parties to help us confirm your identity to comply with money laundering legislation</li>
      <li>Credit reference agencies and debt recovery agents who may check the information against other databases – private and public – to which they have access to</li>
      <li>Any authorities if compelled to do so by law (e.g. to HM Revenue & Customs to fulfil tax compliance obligations)</li>
      <li>Fraud prevention agencies to help prevent crime or where we suspect fraud;</li>
      <li>Any persons, including, but not limited to, insurers, who provide a service or benefits to you or for us in connection with your account(s)</li>
      <li>Our suppliers in order for them to provide services to us and/or to you on our behalf</li>
      <li>Anyone in connection with a re-organisation or merger of the credit union’s business</li>
      <li>Other parties for marketing purposes (if you have agreed to this). We will NEVER sell or share your
        information to other companies for marketing products and services now provided by the credit union
        or in connection with your credit union account.</li>
    </ul>

    <h3>Where we send your information</h3>
    <p>While countries in the European Economic Area all ensure rigorous data protection laws, there are parts of the
      world that may not be quite so rigorous and do not provide the same quality of legal protection and rights when
      it comes to your personal information.</p>
    <p>Cash Box Credit Union does not directly send information to any country outside of the European Economic Area, however,
      any party receiving personal data may also process, transfer and share it for the purposes set out above and in
      limited circumstances this may involve sending your information to countries where data protection laws do not
      provide the same level of data protection as the UK.</p>
    <p><span style="font-weight:bold; font-style:italic;">For example</span>, when complying with international tax regulations we may be required to report personal information
      to the HM Revenue and Customs who may transfer that information to tax authorities in countries where you or a
      connected person may be tax resident.</p>

    <h3>Retaining your information</h3>
    <p>Cash Box Credit Union will need to hold your information for various lengths of time depending on what we use your
      data for. In many cases we will hold this information for a period of time after you have left the credit union.</p>
    <p>To read our policy for retaining members data please contact us at: <a href="tel:01613423266">0161 342 3266</a> or email us at
      <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a></p>

    <h3>Your Rights</h3>
    <p><router-link to="/data-protection-rights">Click here to find out about your data protection rights.</router-link></p>

    <h3>Contact us about your rights</h3>
    <p>For more information about how your rights apply to your membership of the credit union or to make a request under your
      rights you can contact us.  We will aim to respond to your request or query within one month or provide an explanation of the reason for our delay.</p>

    <h3>Contact details of credit union</h3>
    <p>
      Cash Box Credit Union Ltd<br>
      Town Hall<br>
      Market Street<br>
      Denton<br>
      M34 2AP
    </p>
    <p><a href="tel:01613423266">0161 342 3266</a></p>
    <p><a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a></p>

    <h3>Changes to this privacy policy</h3>
    <p>We can update this Privacy Policy at any time and ideally you should check it regularly at <a href="http://www.cashbox.org.uk">www.cashbox.org.uk</a> for
      updates, alternatively this information can be requested at <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a> or from any of our branches.</p>
    <p>We won’t alert you for every small change, but if there are any important changes to the Policy or how we use your
      information we will let you know and where appropriate ask for your consent.</p>

    <h3>External links</h3>
    <p>You may encounter links to external websites when using our website, this policy does not cover these websites and we
      encourage you to view each website’s privacy policy before submitting any information.</p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo() {
    return {
      title: 'Privacy Policy | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
